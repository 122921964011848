<template>
  <div class="body pb-10">
    <div id="spacer"></div>
    <v-row justify="center" class="px-5 ma-0">
      <v-col class="px-0 pb-0">
        <v-text-field
          v-model="search"
          :prepend-inner-icon="'mdi-magnify'"
          outlined
          dense
          :hide-details="true"
          :placeholder="$t('SearchSolution')"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row justify="center" id="help-center" class="px-5 ma-0 mt-2">
      <v-expansion-panels multiple>
        <v-expansion-panel
          v-for="(item,i) in faqBySearch"
          :key="i"
          class="mt-3 border-radius-12"
        >
          <v-expansion-panel-header class="border-radius"><b>{{item.gfaq_groupname}}</b></v-expansion-panel-header>
          <v-expansion-panel-content class="mt-5">
            <!-- {{item.faq_answer}} -->
            <!-- <div align="left" v-html="item.answer" style="font-family: 'Roboto';"></div> -->
            <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. -->
            <v-expansion-panels inset>
              <v-expansion-panel
                v-for="(faq,i) in item.faqs"
                :key="i"
              >
                <v-expansion-panel-header>
                  <b class="break-word">{{faq.faq_question}}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div class="ql-editor" align="left" v-html="faq.faq_answer" style="font-family: 'Roboto';"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </div>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'

export default {
    name: "TermOfService",
    data() {
        return {
          faq : [],
          search : "",
          faqBySearch : []
        }
    },
    watch:{
      search(){
        console.log("Search")
        this.faqBySearch = this.filterByValue(this.faq, this.search)
      }
    },
    methods:{
      filterByValue(array, string) {
          // console.log("filtered array", array)
          // console.log("filtered string", string)
          const filteredArr = array.map(gfaq=>{
            return {
              ...gfaq,
              faqs: gfaq.faqs.filter(faq=>faq.faq_question.toLowerCase().includes(string.toLowerCase()) || faq.faq_answer.toLowerCase().includes(string.toLowerCase()))
            }
          })
          return filteredArr.filter(gfaq=>gfaq.faqs.length>0);
          // return array.filter(o =>
          //   Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
      }
    },
    created() {
      this.faq = []
      this.$store.dispatch("menuManagement/getHelpCenter").then((response) => {
        let regex = /(<p|<img)/gi
        let style = {
          "<p": "<p style='margin-bottom: 4px;'",
          "<img": "<img style='max-width: 100%; display: block; margin-left: auto; margin-right: auto;'"
        }
        for(var data of response){
          if (data.faqs) {
            data.faqs.forEach(function(element) {
              element.faq_answer = element.faq_answer.replaceAll(
                regex,
                (matched) => style[matched]
              )
            }, data.faqs)
          }
          // for (let faq of data.faqs) {
          //   faq.faq_answer = faq.faq_answer.replaceAll(
          //     regex,
          //     (matched) => style[matched]
          //   )
          // }
          // var element = {}
          // element.question = data.faq_question
          // element.answer = data.faq_answer.replaceAll(
          //   regex,
          //   (matched) => style[matched]
          // )
          // element.question_answer = data.faq_question +" "+data.faq_answer
          this.faq.push(data)
        }
        this.faqBySearch = this.faq
      }).catch(e => {
          console.log(e)
      })
    }
}
</script>

<style scoped>
#spacer {
  background: #f5fff7;
  padding: 6px;
}
.background-card {
  background-color: #ffffff;
}
.border-radius{
  box-shadow: 0px 1px 2px rgb(0 0 0 / 5%), 0px 4px 8px rgb(0 0 0 / 3%);
  border-radius: 12px;
}
.border-radius-12 {
  border-radius: 12px!important;
}
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .body-space{
    background-color:#f5fdf7;
    height: 2%;
    width: 100%;
  }
  .body{
    background-color:#FFFFFF;
    height: 100%;
    width: 100%;
  }
  .logo-homepage{
    padding-top: 30px;
    padding-bottom: 30px;    
  }
  .img-body{
    height: 211px;
    width: 243.77471923828125px;
  }
  .label-content{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .body-content{
    padding-top: 10px;
    padding-bottom: 30px;
  }

}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .body-space{
    background-color:#f5fdf7;
    height: 3%;
    width: 100%;
  }
  .body{
    background-color:#FFFFFF;
    height: 100%;
    width: 100%;
  }
  .logo-homepage{
    padding-top: 30px;
    padding-bottom: 30px;    
  }
  .img-body{
    height: 211px;
    width: 243.77471923828125px;
  }
  .label-content{
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }
  .body-content{
    padding-top: 10px;
    padding-bottom: 30px;
  }

}
</style>